<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Hẹn giờ</h6>
        </template>
        <b-row>
          <b-col >
            <b-form-group label="Loại">
              <b-form-select
                  v-model="input.type"
                  :options="this.options.type"
              >
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col >
            <b-form-group label="Mô tả">
              <b-form-input
                  v-model="input.title">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Đối tượng">
              <b-form-select
                  v-model="input.service"
                  :options="this.options.list_id"
                  value-field="id"
                  text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </b-col>


          <b-col >
            <b-form-group label="Trạng thái">
              <b-form-select
                  v-model="input.status"
                  :options="this.options.status">
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Ngày bắt đầu(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="input.from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Ngày kết thúc(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="input.to"
              ></b-form-input>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-primary" @click="getList(1)"
              >Tìm kiếm
            </b-button>
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="linkCreate()"
              >Thêm mới
            </b-button>
          </b-col>
        </b-row>

        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(status)="data">
            <b-badge
                v-if="data.item.status == 1"
                variant="success"
            >Kích hoạt
            </b-badge>
            <b-badge v-if="data.item.status == 0" variant="secondary"
            >Mới tạo
            </b-badge>
            <b-badge v-if="data.item.status == 3" variant="danger"
            >Lỗi xử lý
            </b-badge>
            <b-badge v-if="data.item.status == 2" variant="success"
            >Đã xử lý
            </b-badge>
              <b-badge v-if="data.item.status == 4" variant="warning"
              >Chờ chạy off
              </b-badge>
          </template>

          <template #cell(active)="data">
            <b-badge
                v-if="data.item.active == 1"
                variant="success"
            >Bật
            </b-badge>
            <b-badge v-if="data.item.active == 0" variant="secondary"
            >Tắt
            </b-badge>
            <b-badge v-if="data.item.active == 2" variant="info"
            >Bảo trì
            </b-badge>
          </template>


          <template #cell(action)="data" class="text-right">
            <div class="text-center">
            <router-link
                v-if="data.item.status == 0"
                :to="'/tools/schedule/update?id=' + data.item.id"
            >
              <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa </b-button>
            </router-link>
            <b-button
                v-if="data.item.status == 0"
                @click="update(data.item.id)"
                pill
                variant="success"
                size="sm"
                style="margin-right: 10px;"
            >Duyệt
            </b-button>
            <b-button
                @click="destroy(data.item.id)"
                pill
                variant="danger"
                size="sm"
            >Xóa
            </b-button>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div>
              {{(data.item.created_at)}}
            </div>
          </template>

<!--          <template #cell(updated_at)="data">-->
<!--            <div>-->
<!--              {{formatDate(data.item.updated_at)}}-->
<!--            </div>-->
<!--          </template>-->

        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

Vue.component("multiselect", Multiselect);
const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      input:{
        screen_id:null,
        status: null,
        name: null,
        type: null,
        service: null
      },
      options: {
        list_id: [
          { value: null, text: "Tất cả"},
        ],
        status: [
          { value: null, text: "Tất cả"},
          { value: 0, text: "Mới tạo" },
          { value: 1, text: "Kích hoạt" },
          { value: 2, text: "Đã xử lý" },
          { value: 4, text: "Chờ chạy off" },
          { value: 3, text: "Lỗi xử lý" },
        ],
        type: [
          { value: null, text: "Tất cả"},
          { value: "SERVICE", text: "SERVICE" },
        ],
      },
      listServices : [],
      listProducts: [],
      query: null,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      fields: [
      {
        key: "id",
        label: "ID",
      },
      {
        key: "type",
        label: "Loại",
      },
      {
        key: "title",
        label: "Mô tả"
      },
      //   {
      //     key: "class_name",
      //     label: "Class áp dụng"
      //   },
        {
          key: "list_service",
          label: "Đối tượng"
        },
        {
          key: "list_product",
          label: "Sản phẩm"
        },
        {
          key: "active",
          label: "Hành Động"
        },
        {
          key: "time_set",
          label: "Thời gian chạy",
        },
        {
          key: "time_off",
          label: "Thời gian off",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
      {
        key: "created_at",
        label: "Thời gian tạo",
      },
      // {
      //   key: "updated_at",
      //   label: "Thời gian sửa",
      // },
          {
          key: "action",
          label: "",

        },],
      listAllConfigFee: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Hẹn giờ", route: "schedule" },
    ]);
    // this.listService();
    // this.listProduct();
    this.loadAsync();
  },
  methods: {
    async loadAsync() {
      this.$bus.$emit("show-loading", true);
      await this.listService();
      await this.listProduct();
      this.$bus.$emit("show-loading", false);
    },

    linkCreate() {
      return this.$router.push({ name: "scheduleCreate" });
    },
    linkDetail(id) {
      this.$router.push("/tools/schedule/view?id=" + id);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.input };
      params.page = page;
      params.limit = 50;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listSchedule(params)
        .then((response) => {
          if (response.data.error_code != 0) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.total;
            this.paginate.totalPage = response.data.last_page;
            this.paginate.currentPage = response.data.current_page;

            this.items.forEach(item => {
              let list_service = "";
              let list_product = "";
              let services = JSON.parse(item.list_id);
              let params = JSON.parse(item.params);
              if (item.type === "PRODUCT") {
                let products = services.products;
                services = services.services;

                products.forEach( product => {
                  if (this.listProducts[product] != null) {
                    let productName = this.listProducts[product].name;

                    if ( typeof this.listProducts[product].service !== "undefined" ) {
                      productName += ` - (${this.listProducts[product].service.code})`;
                    }

                    list_product += productName + ", ";
                  }
                });
              }

              services.forEach( service => {
                  if (this.listServices[service] != null) {
                    list_service += this.listServices[service].name + ', ';
                  }
              });
              item.list_service = list_service.slice(0, -2);
              item.list_product = list_product.slice(0, -2);
              item.active = params.active;
            });
            this.$bus.$emit("show-loading", false);
          }
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },

    update(id) {
      let inputParam = {
        status : 1
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.acceptSchedule(id, inputParam)
          .then((response) => {
            if (response.data.error_code == 0) {
              this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.getList(this.currentPage);
            }
          })
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa schedule")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await CmsRepository.deleteSchedule(id);
          this.$bus.$emit("show-loading", false);
          if (0 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            return this.getList(this.currentPage);

          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
            this.getList();
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },

    async listService() {
      // this.$bus.$emit("show-loading", true);
      let params = {
        type: "SERVICE"
      };
      const response = await CmsRepository.listServiceFee(params);
      this.options.list_id = response?.data?.data;
      this.options.list_id.forEach( item => {
        this.listServices[item.id] = item;
      })
      this.options.list_id.unshift({id: null, name: "Tất cả"})
      // this.$bus.$emit("show-loading", false);
      // this.getList();
      return true;
    },

    async listProduct() {
      // this.$bus.$emit("show-loading", true);
      let params = {};
      const response = await CmsRepository.listAllProducts(params);
      this.options.product_id = response?.data?.data;

      this.options.product_id.forEach( item => {
        this.listProducts[item.id] = item;
      })

      await this.getList();
      // this.$bus.$emit("show-loading", false);
      return true;
    },
  },

  created() {
    this.query = this.$route.query;
    // this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },

};
</script>
